@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Medium.ttf) format('truetype');
}

.defaultFont {
  color: #333;
  font-family: 'Montserrat';
  /* , sans-serif; */
 
 
}

.sm {
  font-size:14px;
}

.xsm {
  font-size:12px;
}
